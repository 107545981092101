import React from 'react';

const VulvarPh = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="31.879"
    height="49.441"
    viewBox="0 0 31.879 49.441"
  >
    <defs>
      <clipPath>
        <rect width="31.879" height="49.441" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-1773.06 -692.005)">
      <g transform="translate(1773.06 692.005)">
        <g clipPath="url(#clip-path)">
          <path
            d="M0,27.517a23.866,23.866,0,0,1,1.906-8.8A50.008,50.008,0,0,1,10.722,4.9c1.251-1.447,2.587-2.822,3.917-4.2a1.563,1.563,0,0,1,2.612,0A59.343,59.343,0,0,1,28.9,16.3,28.009,28.009,0,0,1,31.7,25.038a18.946,18.946,0,0,1-2.177,11.711,28.182,28.182,0,0,1-7.476,8.811c-1.581,1.259-3.307,2.336-4.967,3.5a1.876,1.876,0,0,1-2.242.027A34.254,34.254,0,0,1,3.387,38.492,19.685,19.685,0,0,1,0,27.517M15.992,3.472c-.191.18-.313.282-.42.4-.84.911-1.7,1.8-2.51,2.74A50.967,50.967,0,0,0,5.294,18.2,22.809,22.809,0,0,0,2.932,27,16.892,16.892,0,0,0,6.31,37.618a30.6,30.6,0,0,0,8.3,7.848c1.629,1.073,1.044,1.073,2.641.014a29.7,29.7,0,0,0,9-8.889,16.375,16.375,0,0,0,2.61-10.813,25.563,25.563,0,0,0-2.848-8.769A52.713,52.713,0,0,0,19,6.807c-.96-1.122-1.977-2.195-3.01-3.336"
            transform="translate(0 0)"
            fill="currentColor"
          />
          <path
            d="M59.883,118.811a12.375,12.375,0,0,1,1.243-4.974c.528-1.153,1.125-2.279,1.753-3.382a1.468,1.468,0,0,1,2.718-.012,23.748,23.748,0,0,1,2.667,5.974,8.02,8.02,0,0,1-1.116,6.867,24.225,24.225,0,0,1-1.77,2.285,1.428,1.428,0,0,1-2.21.078,13.071,13.071,0,0,1-2.676-3.926,7.188,7.188,0,0,1-.609-2.909m4.421-4.632-.236-.008c-.362,1.076-.761,2.141-1.078,3.23a5.255,5.255,0,0,0,1,4.643,2.924,2.924,0,0,1,.192.423c1.017-1.45,1.745-2.775,1.407-4.389-.21-1-.552-1.976-.859-2.955a8.321,8.321,0,0,0-.426-.946"
            transform="translate(-48.296 -88.342)"
            fill="currentColor"
          />
          <path
            d="M76.359,78.291a1.447,1.447,0,1,1,1.452-1.441,1.47,1.47,0,0,1-1.452,1.441"
            transform="translate(-60.423 -60.809)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default VulvarPh;
